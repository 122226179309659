import React from "react"
import SEO from "../components/seo"
import Layout from '../components/layout';
import { graphql } from "gatsby"
import { Container } from "@material-ui/core"

const TosNew = ({ location, data }) => {
  let result = data.allMarkdownRemark.edges[0].node.html
  return (
    <Layout location={location}>
      <SEO title="Terms Of Service" description="Terms Of Service blix" />
      <Container maxWidth="lg">
        <div className="new-tos">
          <div
            style={{ color: "#000" }}
            dangerouslySetInnerHTML={{ __html: result }}
          />
        </div>
      </Container>
    </Layout>
  )
}

export default TosNew

export const pageQuery = graphql`
  query TosNewIndexQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "tos-new" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            templateKey
          }
        }
      }
    }
  }
`
